import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAdminPanelSettings } from "react-icons/md";
function AdminLogin() {
  const [inputKey, setInputKey] = useState('');
  const navigate = useNavigate();
  const adminKey = process.env.REACT_APP_ADMIN_KEY;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputKey === adminKey) {
      localStorage.setItem("isAdminAuth","true")
      navigate('/admin');
    } else {
      alert('Incorrect key. Please try again.');
    }
  };

  return (
    <div className='adminLogin'>
      <MdAdminPanelSettings />
      <h1>Connexion Administrateur</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Entrez la clé d'administration"
          value={inputKey}
          onChange={(e) => setInputKey(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default AdminLogin;
