import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminDash from "./pages/AdminDash"
import AdminLogin from "./pages/AdminLogin"
import Home from "./pages/Home"
import Requests from './components/Requests';
import Messages from './components/Messages';
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='*' index element={<Home/>}></Route>
          <Route path="/admin/login" element={<AdminLogin/>} />
          <Route path="/admin" element={<AdminDash/>} >
            <Route path='*' index element={<Requests/>}/>
            <Route path='contact' index element={<Messages/>}/>
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
