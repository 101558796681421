import React, { useEffect, useMemo, useState } from 'react';
import { firestore as db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FaSearch, FaUser, FaVoicemail, FaClock } from 'react-icons/fa';

function Messages() {
  const [messages, setMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('fullName');

  const fetchMessages = async () => {
    const querySnapshot = await getDocs(collection(db, 'contacts'));
    const data = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate(), // Convert to JS Date
    }));
    setMessages(data);
    setDisplayedMessages(data); // Show all messages initially
  };

  useMemo(() => {
    fetchMessages();
    setInterval(() => {
      fetchMessages();
    }, 5000);
  }, []);

  useEffect(() => {
    const filteredMessages = messages.filter(message => {
      const field = searchType === 'fullName' ? message.fullName : message.email;
      return field.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setDisplayedMessages(filteredMessages);
  }, [searchTerm, searchType, messages]);

  const markAsRead = async (id) => {
    const messageDoc = doc(db, 'contacts', id);
    await deleteDoc(messageDoc);  // Delete the document from Firestore
  
    // Remove the deleted message from the state
    const updatedMessages = messages.filter(message => message.id !== id);
    setMessages(updatedMessages);
    setDisplayedMessages(updatedMessages);
  };

  return (
    <div className='messagesHolder'>
        <h1>Messages</h1>

        <div className='searchSection'>
            <h1>Rechercher des messages</h1>
            <div>
            <FaSearch />
            <input
                type='text'
                placeholder={`Search by ${searchType}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
            >
                <option value='fullName'>Nom et prénom</option>
                <option value='email'>E-mail</option>
            </select>
            </div>
        </div>

        <ul className='msgCardsHolder'>
            {displayedMessages.map(message => (
            <li key={message.id} className='messageCard'>
                <div className='aboutUser'>
                <label><FaUser /> {message.fullName}</label>
                <label><FaVoicemail /> {message.email}</label>
                </div>
                <div className='messageContent'>
                <p>{message.message}</p>
                <label><FaClock /> {message.timestamp.toLocaleString()}</label> {/* Convert to readable string */}
                </div>
                <button className='readBtn' onClick={() => markAsRead(message.id)}>
                Marquer Comme Lu
                </button>
            </li>
            ))}
        </ul>
    </div>
  );
}

export default Messages;
