import React, { useEffect, useMemo, useState } from 'react';
import { firestore as db } from '../firebase';
import { collection, getDocs,doc,updateDoc } from 'firebase/firestore';
import { CiNoWaitingSign } from 'react-icons/ci';
import { FaCalendarAlt, FaClock, FaDog, FaEye, FaLocationArrow, FaPhoneAlt, FaSearch, FaStar, FaUser, FaVoicemail } from 'react-icons/fa';
import { FaBoxesPacking, FaLocationDot } from 'react-icons/fa6';
import { MdAirlineSeatLegroomNormal } from 'react-icons/md';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';
import { IoMdDoneAll } from 'react-icons/io';

function Requests() {
  const [requests, setRequests] = useState([]);
  const [waitingRequests, setWaitingRequests] = useState([]);
  const [doneRequests, setDoneRequests] = useState([]);
  const [displayedRequests, setDisplayedRequests] = useState([]);
  const [filter, setFilter] = useState('waiting');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('name');

  const fetchRequests = async () => {
    const querySnapshot = await getDocs(collection(db, 'requests'));
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const waiting = data.filter(request => request.status === 'waiting');
    const done = data.filter(request => request.status === 'done');

    setRequests(data);
    setWaitingRequests(waiting);
    setDoneRequests(done);
    setDisplayedRequests(waiting); // Default to waiting requests
  };
 
  useMemo(() => {
    fetchRequests();
    setInterval(() => {
      fetchRequests();
    }, 5000);
  }, []);

  useEffect(() => {
    const filteredRequests = (filter === 'waiting' ? waitingRequests : doneRequests).filter(request => {
      const field = searchType === 'name' ? request.name : request.phone;
      return field.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setDisplayedRequests(filteredRequests);
  }, [filter, searchTerm, searchType, waitingRequests, doneRequests]);

  const handleStatusChange = async (id) => {
    const requestDoc = doc(db, 'requests', id);
    await updateDoc(requestDoc, { status: 'done' });
    const updatedRequests = requests.map(request =>
      request.id === id ? { ...request, status: 'done' } : request
    );

    setRequests(updatedRequests);
    setWaitingRequests(updatedRequests.filter(request => request.status === 'waiting'));
    setDoneRequests(updatedRequests.filter(request => request.status === 'done'));

    if (filter === 'waiting') {
      setDisplayedRequests(waitingRequests.filter(request => request.id !== id));
    }
  };

  return (
    <div className='requestsHolder'>
      <div className='header'>
        <h1>Les Reservations</h1>
        <div>
          <button onClick={() => setFilter('waiting')}><FaClock />en attendant</button>
          <button onClick={() => setFilter('done')}><FaEye />déjà vu</button>
        </div> 
      </div>

      <div className='searchSection'>
        <h1>recherchez vos données</h1>
        <div>
          <FaSearch />
          <input
            type='text'
            placeholder={`Search by ${searchType}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value='name'>Name</option>
            <option value='phone'>Phone Number</option>
          </select>
        </div>
      </div>

      <ul className='reqCardsHolder'>
        {displayedRequests.map(request => (
          <li key={request.id} className='requestCard'>
            <div className='strong'>
              <label><FaPhoneAlt />{request.phone}</label>
              <label><FaCalendarAlt />{request.date} | {request.time}</label>
            </div>  
            <div className='underStrong'>
              <label><FaLocationDot />{request.from}</label>
              <label><FaLocationArrow />{request.to}</label>
            </div>
            
            <div className='aboutUser'>
              <label><FaUser />{request.name}</label>
              <label><FaVoicemail />{request.email}</label>
              <label><FaStar />{request.gamme}</label>
            </div>
            <div className='inCar'>
              <label><MdAirlineSeatLegroomNormal />{request.seats}</label>
              <label><FaDog />{request.animals ? 'Oui' : 'Non'}</label>
              <label><FaBoxesPacking />{request.packages ? 'Oui' : 'Non'}</label>
            </div>
            {filter === 'waiting' && (
              <button className='doneBtn' onClick={() => handleStatusChange(request.id)}><IoMdDoneAll />Marquer comme terminé</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Requests;
