import React from 'react';
import { IoGitPullRequestSharp } from 'react-icons/io5';
import { LuMessagesSquare } from 'react-icons/lu';
import { SiGooglemessages } from 'react-icons/si';
import { Link, Navigate, Outlet } from 'react-router-dom';

function AdminDash() {
  
  if (localStorage.getItem("isAdminAuth") != "true") {
    return <Navigate to="/admin/login"/>
  }
  return (
    <div className='AdminDash'>
      <div className='sideBar'>
        <h1>Taxi&VTC</h1>
        <div>
          <Link to="/admin"><IoGitPullRequestSharp />Reservations</Link>
          <Link to="/admin/contact"><LuMessagesSquare />Messages</Link>
        </div>
        <h2>All rights reserved</h2>
      </div>
      <div className='mobileNavBar'>
        <h1>Taxi&VTC</h1>
        <div>
          <Link to="/admin"><IoGitPullRequestSharp />Reservations</Link>
          <Link to="/admin/contact"><LuMessagesSquare />Messages</Link>
        </div>
      </div>
      <Outlet/>
    </div>
  );
}

export default AdminDash